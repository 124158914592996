
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { TicketCreationForm } from "./TicketCreationWorkflow.vue";

import { Ticket } from "@/inspection/shared/models/Ticket";

@Component<TicketImagesForm>({
  components: {}
})
export default class TicketImagesForm extends Vue
  implements TicketCreationForm {
  @Prop({
    default() {
      return new Ticket();
    }
  })
  protected readonly ticket!: Ticket;

  protected selectedImages: File[] = [];
  protected processingImagesRounds = 0;

  protected get processingImages() {
    return this.processingImagesRounds > 0;
  }

  public validate() {
    this.emitValidate();
    return true;
  }

  public reset() {
    this.selectedImages = [];
    this.ticket.clearImages();
  }

  protected async imagesChanged(images: File[]) {
    this.selectedImages = images;

    try {
      this.processingImagesRounds++;
      this.emitValidate();

      for (const image of images) {
        await this.ticket.imageCollection?.addImageFromFileIfNotExistent(image);
      }
    } finally {
      this.processingImagesRounds--;
      this.emitValidate();
    }
  }

  protected emitValidate() {
    this.$emit("validate", !this.processingImages);
  }
}
