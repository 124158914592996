import {
  GraphQLConnection,
  GraphQLResponse,
  FieldValue,
  isFieldList,
  PaginatedGraphQLResponse,
  OrderByClause,
  MorphConnection,
  FileUpload
} from "./GraphQLConnection";

import axios, { AxiosResponse } from "axios";
import { ObjectUtils } from "@/utils/ObjectUtils";
import { NumericalId } from "@/datastructures/NumericalId";
import { StringUtils } from "@/shared/utils/StringUtils";
import { variableTypes } from "../GraphQLVariableTypes";
import { IndexedDBKeyValueStorage } from "@/storage/IndexedDBKeyValueStorage";
import { AsyncKeyValueStorage } from "@/storage/AsyncKeyValueStorage";
import { DateUtils } from "@/utils/DateUtils";
import { OnlineChecker } from "@/utils/OnlineChecker";
import { storageKeys } from "@/data/storageKeys";
import { UserSettings } from "@/storage/UserSettings";

const defaultTimeOut = 10000;
const axiosConnection = axios.create({
  baseURL: process.env.VUE_APP_BACKEND
});

export class AxiosGraphQLConnection implements GraphQLConnection {
  private cache: AsyncKeyValueStorage<string, string> =
    new IndexedDBKeyValueStorage("graphQlCache", "cache");
  private logger: IAxiosLogger;

  public constructor(logger: IAxiosLogger) {
    this.logger = logger;
  }

  public queryPaginated(
    name: string,
    count: number,
    page: number,
    params: any,
    fields: FieldValue[],
    search: string = "",
    orderBy?: OrderByClause,
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<PaginatedGraphQLResponse> {
    const paginatedFields = [
      { name: "data", fields },
      { name: "paginatorInfo", fields: ["lastPage"] }
    ];

    const countKey = "count";
    const pageKey = "page";
    params[countKey] = count;
    params[pageKey] = page;

    return this.query(
      name,
      params,
      paginatedFields,
      search,
      orderBy,
      skipCache,
      fromCache
    ).then(response => this.extractPaginatedResponse(count, response));
  }

  public query(
    name: string,
    params: any,
    fields: FieldValue[],
    search?: string,
    orderBy?: OrderByClause,
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<GraphQLResponse> {
    const optionalSearch = !!search ? search : undefined;
    const order = orderBy
      ? [{ field: orderBy.field, order: orderBy.order }]
      : undefined;

    const searchKey = "search";
    const orderKey = "orderBy";
    params[searchKey] = optionalSearch;
    params[orderKey] = order;

    return this.request(
      "query",
      name,
      params,
      fields,
      defaultTimeOut,
      skipCache,
      undefined,
      fromCache
    );
  }

  public async queryResponse(
    name: string,
    params: any,
    fields: FieldValue[],
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<GraphQLResponse> {
    fields = [
      {
        name: "data",
        fields
      },
      "permissions"
    ];

    const response = await this.request(
      "query",
      name,
      params,
      fields,
      defaultTimeOut,
      skipCache,
      undefined,
      fromCache
    );

    UserSettings.setGuestPermissions(response.data.permissions);

    return response.data;
  }

  public mutation(
    name: string,
    params: object,
    fields: FieldValue[],
    timeout: number = defaultTimeOut,
    fileUpload?: FileUpload
  ): Promise<GraphQLResponse> {
    return this.request(
      "mutation",
      name,
      params,
      fields,
      timeout,
      true,
      fileUpload
    );
  }

  public uploadFile(
    file: File,
    path: string,
    name: string,
    fields: FieldValue[],
    id?: NumericalId,
    connection?: MorphConnection
  ): Promise<GraphQLResponse> {
    if (!file) {
      throw new Error("file-upload-is-null");
    }
    const convertedFields = this.convertFieldsToString(fields);
    const fileId = id && id.valid ? `, id: ${id.id}` : "";
    let conn = "";

    if (!!connection) {
      conn = `, fileable_id: ${connection.id}, fileable_type: \"${connection.type}\"`;
      if (!!connection.context) {
        conn += `, context: \"${connection.context}\"`;
      }
    }

    const o = {
      query: `mutation ($file: Upload!) {
      upload (file: $file, path: "${path}", filename: "${name}" ${fileId} ${conn}) {
        ${convertedFields}
      }
    }`,
      variables: {
        file: null
      }
    };
    const map = {
      0: ["variables.file"]
    };
    const data = new FormData();
    data.append("operations", JSON.stringify(o));
    data.append("map", JSON.stringify(map));
    data.append("0", file);

    return this.post("upload", data, 60000);
  }

  private request(
    method: string,
    name: string,
    params: object,
    fields: FieldValue[],
    timeout: number = defaultTimeOut,
    skipCache?: boolean,
    fileUpload?: FileUpload,
    fromCache?: boolean
  ): Promise<GraphQLResponse> {
    const query = this.buildQuery(method, "", name, params, fields);
    const data = { query, variables: params };
    const form = new FormData();

    if (fileUpload) {
      const normalizedPath = !fileUpload.path.startsWith("variables")
        ? "variables." + fileUpload.path
        : fileUpload.path;

      const map = {
        0: [normalizedPath]
      };

      form.append("operations", JSON.stringify(data));
      form.append("map", JSON.stringify(map));
      form.append("0", fileUpload.file);
    }

    return this.post(
      name,
      fileUpload ? form : data,
      timeout,
      method,
      skipCache,
      fromCache
    );
  }

  private async post(
    name: string,
    query: any,
    timeout: number = defaultTimeOut,
    method?: string,
    skipCache?: boolean,
    fromCache?: boolean
  ): Promise<GraphQLResponse> {
    const abort = axios.CancelToken.source();
    const storageKey = JSON.stringify(query);

    const timeoutId = setTimeout(() => {
      abort.cancel();
    }, timeout);

    this.logger.logRequest(query);

    if (fromCache) {
      if (await this.cache.has(storageKey)) {
        return JSON.parse((await this.cache.get(storageKey)) ?? "{}");
      } else {
        throw new Error("Query nicht im Cache enthalten");
      }
    }

    try {
      if (
        (await this.cache.has(storageKey)) &&
        OnlineChecker.hasSlowConnection()
      ) {
        return JSON.parse((await this.cache.get(storageKey)) ?? "{}");
      }

      const token = localStorage.getItem("token");
      const accesKey = localStorage.getItem(storageKeys.accessKey);
      const authHeader = token
        ? "Bearer " + localStorage.getItem("token")
        : undefined;

      const headers: { [key: string]: string } = {};
      if (accesKey) headers["AccessKey"] = accesKey;
      if (authHeader) headers["Authorization"] = authHeader;

      const graphQlResponse = await axiosConnection.post("", query, {
        headers,
        cancelToken: abort.token
      });

      clearTimeout(timeoutId);
      this.logger.logResponse(graphQlResponse);
      const rawResponse = graphQlResponse.data;

      const response = this.buildResponse(
        name,
        rawResponse.data,
        rawResponse.errors
      );

      if (
        (response.data === undefined || response.data === null) &&
        !!response.errors
      ) {
        if (StringUtils.isString(response.errors)) {
          throw new Error(response.errors);
        } else {
          throw new Error(response.errors[0].debugMessage);
        }
      }

      this.clearCacheIfOld();

      if (method === "query") {
        await this.cache.set(JSON.stringify(query), JSON.stringify(response));
      }

      return response;
    } catch (reason) {
      this.logger.logError(reason);

      if ((await this.cache.has(storageKey)) && !skipCache) {
        return JSON.parse((await this.cache.get(storageKey)) ?? "");
      }

      throw reason;
    }
  }

  private generateVariableDeclaration(type: string, params: any) {
    const declaration = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => {
        let variableType = variableTypes[type][key];

        if (variableType === undefined) {
          switch (key) {
            case "count":
              variableType = "Int!";
              break;
            case "page":
              variableType = "Int!";
              break;
          }
        }

        return "$" + key + ": " + variableType;
      });

    return declaration.length > 0 ? "(" + declaration.join(", ") + ")" : "";
  }

  private generateVariables(params: any) {
    const variables = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => key + ": $" + key);

    return variables.length > 0 ? "(" + variables.join(", ") + ")" : "";
  }

  private cleanEmptyStrings(params: any) {
    if (params === "") {
      return "a";
    }

    if (ObjectUtils.isObject(params)) {
      for (const param of Object.entries(params)) {
        const [key, nestedParams]: [string, any] = param;
        params[key] = this.cleanEmptyStrings(nestedParams);
      }
    }

    return params;
  }

  private convertFieldsToString(fields: FieldValue[]) {
    let convertedFields = "";

    fields.forEach(field => {
      if (isFieldList(field)) {
        let subfields = this.convertFieldsToString(field.fields);
        if (field.fragment) {
          subfields = `... on ${field.fragment} {
            ${subfields}
          }`;
        }

        convertedFields += field.name;
        convertedFields += `{
          ${subfields}
        }`;
      } else {
        convertedFields += field + "\n";
      }
    });

    return convertedFields;
  }

  private buildQuery(
    operationType: string,
    operationName: string,
    type: string,
    params: object,
    fields: FieldValue[]
  ) {
    // const convertedParams = this.convertParamsToString(params);
    const variableDeclaration = this.generateVariableDeclaration(type, params);
    const variables = this.generateVariables(params);
    const convertedFields = this.convertFieldsToString(fields);

    const body = !!convertedFields ? "{" + convertedFields + "}" : "";
    const query = `
      ${operationType} ${operationName}${variableDeclaration} {
        ${type}${variables} ${body}
      }`;

    return query;
  }

  private extractPaginatedResponse(count: number, response: GraphQLResponse) {
    return {
      data: response.data.data,
      errors: response.errors,
      count: response.data.paginatorInfo.lastPage * count
    };
  }

  private buildResponse(name: string, data: any, errors: any) {
    return {
      data: !!data ? data[name] : undefined,
      errors
    };
  }

  private async clearCacheIfOld() {
    if (!(await this.cache.has("expiresAt"))) {
      this.clearCacheAndSetNewExpiration();
    } else {
      const expiresAt = parseInt(
        (await this.cache.get("expiresAt")) ?? "0",
        10
      );
      if (Date.now() > expiresAt) {
        this.clearCacheAndSetNewExpiration();
      }
    }
  }

  private async clearCacheAndSetNewExpiration() {
    const expiresAt = DateUtils.addDays(3);
    await this.cache.clear();
    await this.cache.set("expiresAt", expiresAt.getTime().toString());
  }
}

export interface IAxiosLogger {
  logRequest(url: string, body?: any): void;
  logResponse(response: AxiosResponse<any>): void;
  logError(reason: any): void;
}
